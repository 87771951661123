import Toast from '../components/_toast';
import {
    createCookie,
    eraseCookie,
    readCookie,
} from './../helpers/cookies-helper';

const cookieName = 'lokum_fav';
const addToFavClass = 'js-add-to-fav';
let counter = 0;

document.addEventListener('click', event => {
    const { target } = event;
    const className = target.getAttribute('class');

    if (className && className.includes(addToFavClass)) {
        event.preventDefault();
        event.stopImmediatePropagation();

        let cookie = readCookie(cookieName);
        let fav = target.dataset.fav;
        let isRemove = false;

        if (!cookie) {
            createCookie(cookieName, [fav].join(','), 365);
            counter = 1;
        } else {
            let offers = cookie.split(',');

            if (!offers.includes(fav)) {
                offers.push(fav);
            } else {
                let itemIdx = offers.indexOf(fav);
                offers.splice(itemIdx, 1);
                isRemove = true;
            }

            eraseCookie(cookieName);
            createCookie(cookieName, offers.join(','), 365);
            counter = offers.length;
        }

        let headline = event.target.dataset.headline;
        let text = event.target.dataset.text;
        if (isRemove) text = event.target.dataset.removeText;

        let addToFavEvent = new CustomEvent('addToFav', {
            detail: {
                id: fav,
                headline: headline,
                text: text,
                counter: counter,
                isRemove,
            },
        });
        document.dispatchEvent(addToFavEvent);
    }
});

document.addEventListener('addToFav', function (e) {
    const { detail } = e;
    const { id, counter, headline, text, isRemove } = detail;
    const favEl = document.querySelector(`[data-fav="${id}"]`);
    const favCountEl = document.querySelector(`[data-fav-count]`);

    const toast = new Toast({
        name: headline,
        text,
        icon: `${theme_path}/dist/img/mark.svg`,
    });

    if (favEl) {
        const favElIcon = favEl.querySelector('.icon');
        if (favElIcon) {
            if (!isRemove) {
                favElIcon.classList.remove('icon-fav');
                favElIcon.classList.add('icon-fav-fill');
            } else {
                favElIcon.classList.add('icon-fav');
                favElIcon.classList.remove('icon-fav-fill');
            }
        }
    }
    if (favCountEl) favCountEl.innerHTML = counter;
});
