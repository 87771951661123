export default class Toast {
    constructor(data) {
        if (!this.set(data)) return;
        this.init();
    }

    set(data) {
        if (!data) return false;

        const { icon, name, text } = data;

        this.toastEl = null;
        this.icon = icon;
        this.name = name;
        this.text = text;

        return true;
    }

    init() {
        this.setToast();
    }

    setToast() {
        let toastUid = this.hashID(6);

        let html = `<div class="toast toast-${toastUid}">
            <div class="toast__left">
                <img src="${this.icon}" />
            </div>
            <div class="toast__right">
                <p class="toast__name">${this.name}</p>
                <p class="toast__text">${this.text}</p>
            </div>
        </div>`;

        document.body.insertAdjacentHTML('beforeEnd', html);
        this.toastEl = document.querySelector(`.toast-${toastUid}`);

        setTimeout(
            function () {
                if (this.toastEl) this.toastEl.remove();
            }.bind(this),
            3000
        );
    }

    hashID(size) {
        const MASK = 0x3d;
        const LETTERS = 'abcdefghijklmnopqrstuvwxyz';
        const NUMBERS = '1234567890';
        const charset = `${NUMBERS}${LETTERS}${LETTERS.toUpperCase()}`.split(
            ''
        );

        const bytes = new Uint8Array(size);
        crypto.getRandomValues(bytes);

        return bytes.reduce((acc, byte) => `${acc}${charset[byte & MASK]}`, '');
    }
}
